import React from 'react';
/*import Lottie from "lottie-react";*/
// import scrollAnimation from "./scrollanimation.json";
//import bgVideo from '../../assets/scarry.mp4'

import herologo from '../../assets/herologo.jpg';

// import pmlogo from '../assets/pmlogo.png';
// import aotflogo from '../assets/aotf.png';
// import wecalogo from '../assets/weca.png';
// import cicplogo from '../assets/cicplogo.png';
// import pytchlogo from '../assets/pytchlogo.png';
// import bbrdlogo from '../assets/bbrdlogo.png';
// import studiologo from '../assets/the-studio-logo.png';

// import nightmare from '../../images/nightmare.png';
// import { Row } from 'react-bootstrap';


// const style = {
//     height: 150,
// };

// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: scrollAnimation,
//     rendererSettings: {
//         preserveAspectRatio: "xMidYMid slice"
//     }
// };

const Home = () => {
    return (
        <>
        <section className="section main-banner" id="top" data-section="section1"
         style={{ 
            // backgroundImage: `url('decoration/herologo.png')`, 
             backgroundSize: 'cover'
        }}>
            {/* <video autoPlay muted loop id="bg-video">
                <source src={bgVideo} type="video/mp4" />
            </video> */}
            <div className="video-overlay header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6" style={{height:'70vh'}}>
                            {/* <img
                                className="demo-bg"
                                
                                src="decoration/herologo.png"
                                alt=""
                            /> */}
                            <div>
                                <h3 className="cy-header">Origin Story</h3>
                                <p className="cy-para" style={{color:'white'}}>Their home verse Cyfloria has disappeared and now they must find a new home. They wander the Metaverse seeking clues to the legionary Neo Cyfloria.</p>
                                <p className="cy-para" style={{color:'white'}}>The clues to the finding Neo Cyfloria are scattered among the many verses. Some have been found already but many are still to be discovered. Those with the parts of the puzzle to discover the way are in constant danger as there are others who want to take control of this hidden verse for their own nefarious purposes.</p>
                            </div>
                        </div>
                        <div className='col-md-6' style={{ textAlign:'right',height:'70vh'}}> 
                            <img src={herologo} alt="cyflorian" style={{width: '400px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            {/* Main Banner Area End */}

           
            {/* <section className="our-event" id="theEvent">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 align-self-center">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item">
                                        <h3>Welcome to the Cyforians</h3>
                                        <p>sdfsdfsd</p>
                                        <p>sdfsdl sfdsfdsfd</p>
                                         <div className="main-button-red">
                                            <div className="scroll-to-section"><a href="/faqs">FIND OUT MORE</a></div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Home;

import { useEffect, useState } from "react";
import {
  // connectWallet,
  // getCurrentWalletConnected,
  mintNFT,
} from "../src/util/interact.js";

import herologo from '../src/assets/herologo.jpg';

const Minter = (props) => {
  //const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  // const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  // const [url, setURL] = useState("");

  useEffect(() => {
    async function getw() {
    //const { address, status } = await getCurrentWalletConnected();

    //setWallet(address);
    setStatus(status);

    //addWalletListener();
    }
    getw();

  }, [status]);

  // function addWalletListener() {
  //   if (window.ethereum) {
  //     window.ethereum.on("accountsChanged", (accounts) => {
  //       if (accounts.length > 0) {
  //         setWallet(accounts[0]);
  //         setStatus("");//👆🏽 Write a message in the text-field above.
  //       } else {
  //         setWallet("");
  //         setStatus("Connect to Metamask using the top right button.");
  //       }
  //     });
  //   } else {
  //     setStatus(
  //       <p>
  //         {" "}
  //         {" "}
  //         <a target="_blank"  rel="noreferrer" href={`https://metamask.io/download.html`}>
  //           You must install Metamask, a virtual Ethereum wallet, in your
  //           browser.
  //         </a>
  //       </p>
  //     );
  //   }
  // }

  // const connectWalletPressed = async () => {
  //   const walletResponse = await connectWallet();
  //   setStatus(walletResponse.status);
  //   setWallet(walletResponse.address);
  // };

  const onMintPressed = async () => {
    const { success, status } = await mintNFT(); //url, name, description);
    setStatus(status);
    if (success) {
      // setName("");
      // setDescription("");
      // setURL("");
      console.log("MINTED");
    }
  };

  return (

  
    <section className="section main-banner" id="top" data-section="section1"
     style={{ 
        // backgroundImage: `url('decoration/herologo.png')`, 
         backgroundSize: 'cover'
    }}>
        {/* <video autoPlay muted loop id="bg-video">
            <source src={bgVideo} type="video/mp4" />
        </video> */}
        <div className="video-overlay header-text">
            <div className="container">
                <div className="row">
                    <div className="col-md-6" style={{height:'70vh'}}>
                        {/* <img
                            className="demo-bg"
                            
                            src="decoration/herologo.png"
                            alt=""
                        /> */}
                        <div>
                            <h3 className="cy-header">Mint a Cyflorian</h3>
                            <p className="cy-para" style={{color:'white'}}>Click on the button to mint. They are free to mint but you still need to pay the gas fee.</p>
                            <p style={{ color: "#25dd96", fontSize: "12px",paddingLeft: '20px', fontWeight:'bold', width: '250px' }}>
                              <a href="https://opensea.io/collection/cyflorians">
                                <span style={{color:'#25dd96'}}>Collection on Opensea</span>
                              </a>
                
                            </p>
                            <button id="mintButton" onClick={onMintPressed}>
                              Mint
                            </button>
                            <p id="status" style={{ color: "white", fontSize: "12px",padding: '20px', fontWeight:'bold', width: '250px' }}>
                              {status}
                            </p>
                      
                        </div>
                    </div>
                    <div className='col-md-6' style={{ textAlign:'right',height:'70vh'}}> 
                        <img src={herologo} alt="cyflorian" style={{width: '400px'}}/>
                    </div>
                  

                    
                </div>
            </div>
        </div>
    </section>

  





    
  );
};

export default Minter;

  /* <section className="our-offer" id="theOffer">
    <div className="container">


<div className="demo-wrap" style={{height:'300px'}}>
<img
className="demo-bg"

src="decoration/herologo.png"
alt=""
/>
<div className="demo-content">
<h3 className='cy-header' style={{padding:'20px',paddingLeft:'54px'}} >Mint a Cyflorian</h3>


<div className="button-container-div">
      <button id="mintButton" onClick={onMintPressed}>
        Mint
      </button>
      <p id="status" style={{ color: "white", fontSize: "18px", fontWeight:'bold', width: '250px' }}>
        {status}
      </p>
      </div>


</div>
</div>
  </div>

</section> */

        /* <form><h2>🖼 Link to asset: </h2>
        <input
          type="text"
          placeholder="e.g. https://gateway.pinata.cloud/ipfs/<hash>"
          onChange={(event) => setURL(event.target.value)}
        />
        <h2>🤔 Name: </h2>
        <input
          type="text"
          placeholder="e.g. My first NFT!"
          onChange={(event) => setName(event.target.value)}
        />

        <h2>✍️ Description: </h2>
        <input
          type="text"
          placeholder="e.g. Even cooler than cryptokitties ;)"
          onChange={(event) => setDescription(event.target.value)}
        />  </form> */
    
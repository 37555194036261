import './App.css';
import Minter from './Minter'
import Navbarz from './util/navbar';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import GalleryItem from './components/gallery/GalleryItem';
// import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import Gallery from './components/gallery/Gallery';
import Home from './components/home/Home';
// import { Parallax } from 'react-parallax';
import outBck from './assets/out-bck-bw-faded.jpg';
// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";


function App() {
  /*
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  */
  return (
    <div className="App">
      <BrowserRouter>
      {/* <Parallax
        blur={{ min: -15, max: 15 }}
        bgImage={outBck}
        bgImageAlt="cyflorian"
        strength={-200}
    > */}
     <div style={{ backgroundImage: `url(${outBck})`,backgroundSize: 'contain' }}>


    {/* <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "transparent",
          },
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 6,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    /> */}
  
      
        <Navbarz />
      
        <Routes>
          <Route  element={<Minter />} path="/mint" />
          <Route element={<Gallery />} path="/gallery" />
          <Route  path="/gallery/:id" render={({ match }) => {
            // no await 
            const itemId = String(match.params.id);
            return (<GalleryItem itemId={itemId} />)
          }} />
          <Route element={<Home />} path="/" />
        </Routes>
       
        <Footer />
        </div>
        {/* </Parallax> */}
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
//import { NavLink } from 'react-router-dom';

/*
var style = {
    backgroundColor: "#000",
    // borderTop: "1px solid #E7E7E7",
    // textAlign: "center",
    // padding: "20px",
    // position: "fixed",
    // left: "0",
    // bottom: "0",
    // height: "60px",
    // width: "100%",
}*/


//var phantom = {
    // display: 'block',
    // padding: '20px',
    // height: '60px',

//}

function Footer({ children }) {
    return (
        <div className="container footer" style={{backgroundColor:"black"}}>
            <div className="row">
                {/* <div style={phantom} /> */}
                <div style={{width: '70%'}}>
                    <nav className="main-nav">
                        <ul className="nav" id="">
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to="/list">
                                    Mailing List
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/policies">
                                    Policies
                                </NavLink>
                            </li> */}
                            <li className="nav-item" style={{fontSize:'12px', padding:'20px', fontWeight:'400', color:'#FFF', marginTop: '20px'}}>
                               {'Images were produced using Midjourney.'}
                            </li>
                          
                        </ul>
                    </nav>
                </div>
                <div style={{width: '30%', textAlign: 'right'}}>
                <nav className="main-nav">
                        <ul className="nav" id="">
                            
                            <li className="nav-item" style={{fontSize:'12px', padding:'20px', fontWeight:'400', color:'#FFF',width:'100%', marginTop: '20px',textAlign:'right'}}>
                            {'No roadmap. No discord. No utility.'}
                            </li>
                          
                        </ul>
                    </nav>
                    
                    
                    </div>
            </div>
        </div>

    )
}

export default Footer


    // import axios from 'axios';
   
    import React from "react";
    // import { Canvas } from  "@react-three/fiber";//"react-three-fiber";
    // import { OrbitControls, useGLTF } from "@react-three/drei";
    import { useState, useEffect} from "react";
    import { getMarketNftsLiveById } from "../../util/wallet";
    
    const Card = (props) => {
        // const { item } = props;
        //console.log(props);
        
        const name = props.itemData.name;
        const description = props.itemData.description;
        const glbPath = props.itemData.glbPath;
        //const url = '/tim.jpg';

        // const { name, description, glbPath } = props;
        console.log('description',description);
        console.log('glbPath',glbPath);
        
        return (
            <div className="col">
                <div className="card h-600">
        

                    <div className="card-body">
                        <h5 className="card-title">{name}</h5>
                        <p className="card-text">{description}</p>
                        
                    </div>
                </div>
            </div>
        );
    };
    
    const GalleryItem = ({ itemId }) => {
        const [loading, setLoading] = useState(true);
        const [item, setItem] = useState({
            name: 'Loading...',
            description: 'Loading...',
            glbPath: '',
            url: ''
        });

        useEffect(() => {
            const func = async () => {
                const props = await getMarketNftsLiveById(itemId);
                console.log(props);
                setItem(props);
                setLoading(false);
            }
            func();
        }, [itemId]);
        return (
            <div>
                <div className="row row-cols-1 row-cols-md-3 g-4" style={{background:'black'}}>
                {loading  ? <div style={{width:'100%', height:'2000px', background:'transparent'}}>
                    <div className='centerLoader'>
                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </div>
                    </div>
                    :<>
                    <div className="row row-cols-1 row-cols-md-3 g-4"  style={{background:'black',marginTop:'260px',width:'100%'}}>
                    <Card itemData={item} />
                    </div>
                    </>}
                </div>
            </div>
           
        );
    };
    
    export default GalleryItem;
    
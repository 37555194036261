/* eslint-disable jsx-a11y/anchor-has-content */

    import axios from 'axios';
    import React, {useEffect, useState} from 'react';
    import { getMarketNftsLive} from '../../util/wallet';
    import '../../App.css';
    const Card = (props) => {
        return (
            <div className="col">
                <div className="card h-100">
                    <img src={props.img} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">{props.name}</h5>
                        <p className="card-text">{props.description}</p>
                        <p>
                        <a aria-label='link' style={{color:'red'}} href={"/gallery/" + parseInt(props.token_id)} alt={'link'}>{"more"}</a>
                      
                        </p>
                    </div>
                </div>
            </div>
        
    );
};

const Gallery = () => {
    //const loaded = useSelector(selectLoaded);
    //const storage = useSelector(selectStorage);
    //const [loaded, setLoaded] = useState(true);
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const func = async () => {
            let tokens = [];
            const marketNfts = await getMarketNftsLive();
            // console.log(marketNfts)
            for (let i = 0; i < marketNfts.length; i++) {
                if (marketNfts[i].url) {
                    let token = {};
                    token.token_id = marketNfts[i].token_id;
                    const response = await axios.get('https://ipfs.io/ipfs/' + marketNfts[i].url);
                    let data = response.data;
                    // console.log('data',data);
                    token.name = data.name;
                    token.description = data.description;
                    if (data.displayUri) token.img = 'https://ipfs.io/ipfs/' + data.displayUri.slice(7);
                    tokens.push(token);
                }
            }
            setNfts(tokens);
            setLoading(false);
            console.log('FINSIHED getting user market place nfts');
            setLoading(false);
        };
        console.log('START getting user market place nfts');
        func();

        // eslint-disable-next-line
    }, []);

    return (





        <section className="our-offer" id="theOffer">
            <div className="container">


<div className="demo-wrap" style={{height:'300px'}}>
  <img
    className="demo-bg"
    
    src="decoration/herologo.png"
    alt=""
  />
  <div className="demo-content">
    <h3 className='cy-header' style={{padding:'20px',paddingLeft:'54px'}} >Gallery</h3>
  </div>
</div>
            {loading  ? <div style={{width:'100%', height:'2000px', background:'transparent'}}>
                    <div className='centerLoader'>
                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </div>
                    </div>
:<>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading">
                           {nfts.length > 0 ? <h2>Cyflorians</h2> : null }
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row row-cols-1 row-cols-md-3 g-4">
                                {nfts.map((nft, index) => {
                                    return <Card {...nft} key={index} />;
                                })}
                            </div>
                        </div>
                    </div>



                    {/* <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="card-area-content">
                                    <div className="card-heading">XXX</div>
                                    <div className="card-title">The Event</div>
                                    <p>Art party you probably haven't heard of them dreamcatcher 3 wolf moon, brunch yuccie brooklyn occupy
                                        health goth bushwick leggings. Pickled hell of locavore, kinfolk 3 wolf moon drinking vinegar try-hard
                                        DIY farm-to-table. Brooklyn whatever meh</p>
                                    <p className="scroll-to-section"><a href="#theEvent">read more ..</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="card-area-content">
                                    <div className="card-heading">XXX</div>
                                    <div className="card-title">The Film</div>
                                    <p>I'm baby master cleanse poke offal fashion axe typewriter la croix street art lumbersexual godard
                                        pour-over. Fam raw denim schlitz iceland cliche cornhole irony tofu snackwave twee austin heirloom
                                        swag locavore ethical.</p>
                                    <p className="scroll-to-section"><a href="#theFilm">read more ..</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="card-area-content">
                                    <div className="card-heading">XXX</div>
                                    <div className="card-title">The Game</div>
                                    <p>Prism meditation affogato, food truck cray snackwave jianbing. Cronut letterpress air plant tilde
                                        selvage, ugh DIY ethical put a bird on it dreamcatcher bushwick raclette. Vexillologist kale chips
                                        actually, authentic snackwave beard quinoa messenger bag.</p>
                                    <p className="scroll-to-section"><a href="#theGame">read more ..</a></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="card-area-content">
                                    <div className="card-heading">XXX</div>
                                    <div className="card-title">The NFTs</div>
                                    <p>Polaroid ennui echo park, craft beer freegan fashion axe banh mi synth viral forage messenger bag.
                                        Farm-to-table keytar crucifix green juice cliche stumptown humblebrag beard artisan. Pok pok whatever
                                        schlitz, quinoa art party four dollar toast disrupt microdosing kitsch.</p>
                                    <p className="scroll-to-section"><a href="#theNFTs">read more ..</a></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                </>}
            </div>

        </section>









    );
};

export default Gallery;

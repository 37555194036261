import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import '../assets/elgocBold.otf';
//import homeLogo from '../assets/homelogo.png';
import { Navbar, Container } from 'react-bootstrap';
import SocialMediaButtons from 'react-social-media-buttons';
import {
    connectWallet,
    getCurrentWalletConnected,
} from "../util/interact.js";
import '../App.css';

const Navbarz = (props) => {

    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        async function getw() {
            const { address, status } = await getCurrentWalletConnected();

            setWallet(address);
            setStatus(status);

            addWalletListener();
        }
        getw();

    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    setStatus("");//👆🏽 Write a message in the text-field above.
                } else {
                    setWallet("");
                    setStatus("Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                
                <a id="cyTopNavWalletLink" target="_blank"   rel="noreferrer" href={`https://metamask.io/download.html`}>
                <span className="cyTopNavWalletLink">You must install Metamask, a virtual Ethereum wallet, in your
               browser.</span>
             </a>
               
            );
        }
    }

    const connectWalletPressed = async () => {
        const walletResponse = await connectWallet();
        setStatus(walletResponse.status);
        setWallet(walletResponse.address);
    };

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header-area');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const links = [ 'https://www.twitter.com/cyflorians', 'https://www.instagram.com/cyfloria'];
 
    const buttonStyle = {
      backgroundColor: '#000000',
      borderRadius: '50%',
    };
   
    const iconStyle = { color: '#ffffff' };
   
    return (
        <>
            <header className="header-area header-sticky cyTopNav">
                <div className="container cyTopNav">
                    <div className="row">
                        <div key={'ccc1'} className="col-12">
                            <Navbar expand="lg" className="cyTopNav">
                                <Container className='cyTopNav'>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" className='cyTopNav'/>
                                    <Navbar.Collapse id="basic-navbar-nav" className='cyTopNav'>

                                        <div key={'bbb1'} style={{width: '70%',backgroundColor:'transparent', height: '50px'}}>

                                            <nav className="main-nav"> 
                                                <ul className="nav d-flex flex-nowrap" id="navbarHolder">
                                                    
                                                    <li key={'dfgdfgdfg1'} className="nav-item"> 
                                                        <NavLink className="nav-link active" aria-current="page" to="/">
                                                        <span style={{fontFamily: 'Orbitron' ,fontWeight:'900', color:'mint'}}>Cyflorians</span>
                                                        {/* <img src={homeLogo} className="logohome" alt="HOME" /> */}
                                                        </NavLink>
                                                    </li>

                                                    <li key={'dfgdfgdfg2'} className="nav-item">
                                                        <NavLink className="nav-link" to="/mint">
                                                        <span style={{fontFamily: 'Orbitron' ,fontWeight:'400', color:'#25dd96'}}>Mint</span>
                                                        </NavLink>
                                                    </li>

                                                    {/* <li className="nav-item"> 
                                                        <NavLink className="nav-link" to="/gallery">
                                                        <span style={{fontFamily: 'Orbitron' ,fontWeight:'400', color:'#25dd96'}}>Gallery</span>
                                                        </NavLink>
                                                    </li>  */}


                                                    <li key={'dfgdfgdfg3'} className="nav-item">


                                                    <div key={'nnn3'} style={{marginTop:'5px'}}>
                                                        <SocialMediaButtons color={"#25dd96"} links={links} buttonStyle={buttonStyle} iconStyle={iconStyle} />
                                                    </div>
 
                                                    </li>
                                                </ul>
                                            </nav> 
                                        </div>

                                        <div key={'bbb2'} style={{width: '30%',backgroundColor:'transparent', height: '50px'}}>

                                            {/* <li className="nav-item"> */}

                                                <button id="walletButton" onClick={connectWalletPressed}>
                                                    {walletAddress.length > 0 ? (
                                                        "Connected: " +
                                                        String(walletAddress).substring(0, 6) +
                                                        "..." +
                                                        String(walletAddress).substring(38)
                                                    ) : (
                                                        <span>Connect Wallet</span>
                                                    )}
                                                </button>

                                            {/* </li> */}

                                        </div>
                                           
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>

                            <div key={'ccc3'} style={{backgroundColor:'#25dd96',color:'black',fontWeight:'bold'}}><p style={{fontSize: '10px',textAlign:'center'}}>{status}</p></div>

                        </div>
                    </div>
                </div>
                <div>
                    
                </div>
            </header>


        </>


    );
};

export default Navbarz;




